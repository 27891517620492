import React from 'react';
import Counter from './Counter';
import Header from './Header';

interface Props {
  logo: string;
}

const App: React.FC<Props> = (props) => {
  return (
    <div className="App">
      <Header logo={props.logo} text="Welcome to MyMangaList!" />
      <p className="App-intro">
        This page is currently under construction. Coming soon ...
      </p>
      <Counter />
    </div>
  );
};

export default App;
