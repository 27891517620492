import React from 'react';

interface Props {
  logo: string;
  text: string;
}

const Header: React.FC<Props> = (props: Props) => {
  return (
    <header className="App-header">
      <img src={props.logo} className="App-logo" alt="logo" />
      <h1 className="App-title">{props.text}</h1>
    </header>
  );
};

export default Header;
