import React, { useState } from 'react';

const Counter: React.FC = () => {
  const [counter, setCounter] = useState(0);

  return (
    <section className="App-counter">
      <em>You clicked {counter} times out of boredom!</em>
      <button onClick={() => setCounter(counter + 1)}>+1</button>
    </section>
  );
};

export default Counter;
